import React, {ReactNode, useContext} from 'react';
import clsx from 'clsx';
import {useFocusManager} from '@react-aria/focus';
import {TabContext} from './tabs-context';

export interface TabProps {
  className?: string;
  index?: number;
  children: ReactNode;
  isDisabled?: boolean;
}
export function Tab({index, className, isDisabled, children}: TabProps) {
  const {selectedTab, setSelectedTab, tabsRef, size, id} =
    useContext(TabContext);
  const isSelected = index === selectedTab;
  const focusManager = useFocusManager();

  const mergedClassname = clsx(
    'tracking-wide overflow-hidden capitalize text-sm flex-shrink-0 flex items-center justify-center outline-none transition-colors',
    'focus-visible:ring focus-visible:ring-2 ring-inset rounded',
    textColor({isDisabled, isSelected}),
    className,
    {
      'px-18 h-48': !size || size === 'md',
      'px-12 h-32': size === 'sm',
      'cursor-pointer': !isDisabled,
    }
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (e.key) {
      case 'ArrowLeft':
        focusManager.focusPrevious();
        break;
      case 'ArrowRight':
        focusManager.focusNext();
        break;
      case 'Home':
        focusManager.focusFirst();
        break;
      case 'End':
        focusManager.focusLast();
        break;
    }
  };

  const tabIndex = isSelected ? 0 : -1;

  return (
    <button
      disabled={isDisabled}
      id={`${id}-${index}-tab`}
      aria-controls={`${id}-${index}-tabpanel`}
      type="button"
      role="tab"
      aria-selected={isSelected}
      tabIndex={isDisabled ? undefined : tabIndex}
      onKeyDown={onKeyDown}
      onClick={() => {
        setSelectedTab(index!);
      }}
      className={mergedClassname}
      ref={el => {
        if (tabsRef.current && el) {
          tabsRef.current[index!] = el;
        }
      }}
    >
      {children}
    </button>
  );
}

interface TextColorProps {
  isDisabled?: boolean;
  isSelected: boolean;
}
function textColor({isDisabled, isSelected}: TextColorProps): string {
  if (isDisabled) {
    return 'text-disabled cursor-default';
  }
  if (isSelected) {
    return 'text-primary';
  }
  return 'text-muted hover:text-main';
}
