import {FontFaceConfig} from './font-face-config';

export function loadFonts(
  fonts: FontFaceConfig[],
  prefixSrc?: (src: string) => string
): Promise<FontFace[]> {
  const promises = fonts.map(async fontConfig => {
    const loadedFont = Array.from(document.fonts.values()).find(current => {
      return current.family === fontConfig.family;
    });
    if (loadedFont) {
      return loadedFont.loaded;
    }
    const fontFace = new FontFace(
      fontConfig.family,
      `url(${prefixSrc ? prefixSrc(fontConfig.src) : fontConfig.src})`,
      fontConfig.descriptors
    );
    document.fonts.add(fontFace);
    return fontFace.load();
  });
  return Promise.all(promises);
}
